import api from './api'

export default {
    country(){
        return api.execute(`get`,`/country`)
    },
    os(){
        return api.execute(`get`,`/os`)
    },
    edit(data){
        return api.execute(`post`,`/offer/modify`,data)
    },
    add(data){
        return api.execute(`post`,`/offer/add`,data)
    },
    getcarrier(data){
        return api.execute(`post`,`/carrier`,data)
    },
    get(data){
        return api.execute(`post`,`/offer/get`,data)
    },
    remove(data){
        return api.execute(`post`,`/offer/remove`,data)
    },
    modify(data){
        return api.execute(`post`,`/offer/modify`,data)
    },
    getManagerOfferList(data){
        return api.execute(`post`,`/manager/offers`,data)
    },
    getcity(data){
        return api.execute(`post`,`/city`,data)
    },
    cloneOffer(data){
        return api.execute(`post`,`/offer/clone`,data)
    }
}
