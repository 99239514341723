<template>
  <div>
  <CCard class="col-md-12">
    <CRow class="p-3">
      <h4 v-if="edit" class="color-light ml-2 page_title"><span class="ul_text">EDI</span>T OFFER</h4> 
      <h4 v-else class="color-light ml-2 page_title"><span class="ul_text">NE</span>W OFFER</h4> 
      <div class="card-header-actions float-right" @click="openModel()">
            <small class="text-white ml-2"
              ><CIcon name="cil-info"/></small></div>
    </CRow>
    <div class="offerinfo">
          <CRow class="ml-2 mt-3">
          <label><strong class="col-md-10">Offer Name :&nbsp;<span style="color:red">*</span></strong></label>
          </CRow>
          <CRow class="ml-2">
              <CInput
                class="col-md-8"
                size="sm"
                id="input-33"
                v-model="mainObject.offername"
                type="text"
                required
                placeholder="Write a name for this offer"
                :state="validation"
                maxlength="100"
              ></CInput>
          </CRow>

        <CRow class="ml-2">
          <label><strong class="col-md-10">Offer URL :&nbsp;<span style="color:red">*</span></strong>
          <small title="Show Macros" @click="collapse = !collapse" class="link">Add Macros</small>
          </label>
        </CRow>
        <CCollapse :show="collapse" class="ml-4">
          <CRow class="link col-md-12" >
          <small v-for="(value, index) in macros" :key="index" style="background:#e6e6e6;">
            <label class="p-1" @click="addMacros(value)">{{value}}</label>
          </small>
          </CRow></CCollapse>
        <CRow class="ml-2">
         <CTextarea
                class="col-md-8 url_text"
                size="sm"
                id="input-4"
                v-model="mainObject.offerurl"
                type="text"
                required
                placeholder="Tracking link"
                @input="checkButton()"
                maxlength="2000"
            ></CTextarea>
        </CRow>

        <CRow class="ml-2">
              <label class="col-md-10"><strong>Postback Password : </strong>
            <small class="text-white" @click="show_pwd_info=true"><CIcon name="cil-info"/></small>
              <small title="Custom Postback"  class="link ml-2" @click="openCustModal()">
                Customize</small>
              </label>
        </CRow>
        <CRow class="ml-2">
              <label class="col-md-8" style="font-size:12px;">{{this.conve_url}}?token={{mainObject.postbackurl}}</label>
              <CInput
                class="col-md-8"
                size="sm"
                id="input-5"
                v-model="mainObject.postbackurl"
                type="text"
                required
                placeholder="token={POSTBACK_PASSWORD}"
                style="margin-top: -5px;"
                maxlength="150"
              ></CInput>
              
            <CModal
            title="Postback Password"
            :show.sync="show_pwd_info"
            size="lg"
            :centered="true"
            scrollable
            color="dark"
            >
            If you have configured a postback password, don’t forget to add the key parameter. You can set the postback password in Settings
           <template #footer>
            <CButton @click="show_pwd_info = false" color="danger">Close</CButton>
          </template>
          </CModal>
        </CRow>


        <CRow class="mt-2 ml-2 col-md-12">
          <label class=""><strong>Category :</strong></label>
        
          <CInputRadio class="ml-2" name="Category" id="mainsteam0" value="0" @input="mainObject.category = $event.target.value" label="Mainstream">
          </CInputRadio>
          <CInputRadio id="mainsteam1" name="Category" value="1" class="ml-2" @input="mainObject.category = $event.target.value" label="Adult">
          </CInputRadio>
        </CRow>

        <CRow class="ml-2 mt-3 col-md-12">
              <label class=""><strong>Revenue tracking :</strong></label>
             
                <CInputRadio
                  name="revtrack"
                  class="ml-2"
                  @input="mainObject.revenuetrack = $event.target.value"
                  @change="check('0')"
                  value="0"
                  label="Don't Track"
                  id="revenuetrack0"
                >
                </CInputRadio>
                <CInputRadio id="revenuetrack2" name="revtrack" class="ml-2" @input="mainObject.revenuetrack = $event.target.value" @change="check('0')" value="2" label="Automatic (macros)">
                </CInputRadio>
                <CInputRadio id="revenuetrack1" name="revtrack" class="ml-2" @input="mainObject.revenuetrack = $event.target.value" @change="check('1','1')" value="1" label="Fixed"></CInputRadio>
                <div v-if="show_box" size="sm" >
                    <CInput
                      append="$"
                      class="ml-2"
                      size="sm"
                      id="input-6"
                      v-model="mainObject.revenuevalue"
                      type="number"
                      required
                      placeholder="0.5"
                      :state="validateNumber"
                    ></CInput>
                  </div>
            </CRow>

      <CRow class="ml-2 mt-3 col-md-12">
          <label class=""><strong>Daily capping :</strong></label>
            <CInput
                class="col-md-3 col-6"
                size="sm"
                id="input-2"
                v-model="mainObject.capping"
                type="number"
                required
                placeholder="0"
                value="0"
                :state="validateNumber"
              ></CInput>
      </CRow>
      </div>
      <!-- <CRow class="ml-2 mt-1 col-md-12">
              <label class=""><strong>Events URL :</strong></label>
      </CRow> -->
      <!-- <CRow class="ml-2">
              <CInput
                class="col-md-8"
                size="sm"
                id="input-3"
                v-model="mainObject.eventurl"
                type="text"
                required
                placeholder="https://track.fraudavoid.io/event?hitId={fraudavoid_click_id}&txId={your_tx_id}&other_macros={your_macro_name}"
              ></CInput>
              <label
                class="ml-3" style="margin-top: -15px;"
              ><small>This is the URL where you should send events to. For more information about this advanced feature and its usage you can refer to our documentation.
                </small>
              </label>
            </CRow> -->
  </CCard>


    <CCard class="col-md-12">
      <div class="offer_targetting">
      <CRow class="p-3">
      <h4 class="color-light ml-2 page_title"><span class="ul_text">OF</span>FER TARGETING</h4> 
      </CRow>
          <CRow class="col-md-12 col-12 mt-2">
              <label class="col-md-2"><strong>Countries :</strong></label>
              <CButtonGroup class="col-md-2 col-12">
                <CInputRadio id="country1" name="Country" @input="mainObject.country_clude = $event.target.value"  value="true" label="Include">
                </CInputRadio>
                <CInputRadio id="country2" name="Country" @input="mainObject.country_clude = $event.target.value" class="ml-2" value="false" label="Exclude">
                </CInputRadio>
                <!-- <CButton variant="outline-primary" v-model="mainObject.country_clude" value='true'>Include</CButton>
                <CButton variant="outline-primary" v-model="mainObject.country_clude" value='false'>Exclude</CButton>-->
              </CButtonGroup>
              <multiselect
                class="col-md-3"
                v-model="mainObject.country"
                tag-placeholder="Add this as new tag"
                placeholder="Countries:"
                label="name"
                track-by="_id"
                :options="country"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                @input="getcarrier"
                :preselect-first="true"
                :close-on-select="false"
              >
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
              </multiselect>
            </CRow>

            <CRow class="col-md-12 col-12 mt-2" v-show="show_city">
              <label class="col-md-2"><strong>City :</strong></label>
              <CButtonGroup class="col-md-2 col-12">
                <CInputRadio id="city1" name="City" @input="mainObject.city_clude = $event.target.value"  value="true" label="Include">
                </CInputRadio>
                <CInputRadio id="city2" name="City" @input="mainObject.city_clude = $event.target.value" class="ml-2" value="false" label="Exclude">
                </CInputRadio>
                
              </CButtonGroup>
              <multiselect
                class="col-md-3"
                v-model="mainObject.city"
                tag-placeholder="Add this as new tag"
                placeholder="City:"
                label="name"
                track-by="_id"
                :options="city"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                :preselect-first="false"
                :close-on-select="false"
              >
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
              </multiselect>
            </CRow>

            <CRow class="mt-3 col-md-12">
              <label class="col-md-2"><strong>Carriers :</strong></label>
              <CButtonGroup class="col-md-2 col-12">
                <CInputRadio id="carrier1" name="carrier" @input="mainObject.carriers_clude = $event.target.value" value="true" label="Include">
                </CInputRadio>
                <CInputRadio id="carrier2" name="carrier" class="ml-2" @input="mainObject.carriers_clude = $event.target.value" value="false" label="Exclude">
                </CInputRadio>
                <!-- <CButton variant="outline-primary" v-model="mainObject.carriers_clude">Include</CButton>
                <CButton variant="outline-primary" v-model="mainObject.carriers_clude">Exclude</CButton>-->
              </CButtonGroup>
              <multiselect
                class="col-md-3"
                v-model="mainObject.carriers"
                tag-placeholder="Add this as new tag"
                placeholder="Carriers:"
                label="name"
                track-by="_id"
                :options="carrier"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                :close-on-select="false"
              >
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
              </multiselect>
            </CRow>

            <CRow class="mt-3 col-md-12">
              <label class="col-md-2"><strong>Network Type :</strong></label>
                <span class="col-md-2 col-12"><CInputCheckbox id="enableNetwork" :checked.sync="mainObject.networktype.enable" value="true" label="Enable Network Type targeting" @change="changeNetworkType()">
                </CInputCheckbox></span>
                  <CRow class="col-md-1 col-12 ml-3" v-show="mainObject.networktype.enable">
                  
                  <CButtonGroup class="">
                  <CInputRadio id="network_type1" name="networktype" value="mobile" @input="mainObject.networktype.name= $event.target.value" label="Mobile">
                  </CInputRadio>
                  <CInputRadio
                    name="networktype"
                    value="wifi"
                    id="network_type2"
                    @input="mainObject.networktype.name= $event.target.value"
                    class="ml-2"
                    label="Wifi"
                  >
                  </CInputRadio>
                  </CButtonGroup>
                  </CRow>
            </CRow>

            <CRow class="mt-2 col-md-12">
              <label class="col-md-2"><strong>Device Type :</strong></label>
              <CButtonGroup class="col-md-2 col-12">
                <CInputRadio id="devicetype1" name="devicetype" @input="mainObject.devicetype_clude= $event.target.value" value="true" label="Include">
                </CInputRadio>
                <CInputRadio id="devicetype2" name="devicetype" class="ml-2" @input="mainObject.devicetype_clude= $event.target.value" value="false" label="Exclude">
                </CInputRadio>
                <!-- <CButton variant="outline-primary"  v-model="mainObject.devicetype_clude">Include</CButton>
                <CButton variant="outline-primary"  v-model="mainObject.devicetype_clude">Exclude</CButton>-->
              </CButtonGroup>
              <multiselect
                class="col-md-3"
                v-model="mainObject.devicetype"
                tag-placeholder="Add this as new tag"
                placeholder="Device Type:"
                label="name"
                track-by="did"
                :options="device"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                :close-on-select="false"
              >
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
              </multiselect>
            </CRow>

            <CRow class="mt-2 col-md-12">
              <label class="col-md-2"><strong>OS :</strong></label>
              <CButtonGroup class="col-md-2 col-12">
                <CInputRadio id="os1" name="os" @input="mainObject.os_clude= $event.target.value" value="true" label="Include">
                </CInputRadio>
                <CInputRadio id="os2" name="os" class="ml-2" @input="mainObject.os_clude= $event.target.value" value="false" label="Exclude">
                </CInputRadio>
                <!-- <CButton variant="outline-primary" v-model="mainObject.os_clude">Include</CButton>
                <CButton variant="outline-primary" v-model="mainObject.os_clude">Exclude</CButton>-->
              </CButtonGroup>
              <multiselect
                class="col-md-3"
                v-model="mainObject.os"
                tag-placeholder="Add this as new tag"
                placeholder="OS:"
                label="os"
                track-by="_id"
                :options="os"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                :close-on-select="false"
              >
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
              </multiselect>
            </CRow>
            </div>
    </CCard>
    <CCard v-if="edit" class="col-md-12">
      <CRow class="ml-2 col-md-12 p-2">
        <label
          ><strong class="">
            Status:
          </strong></label>
        <CSwitch
              class="ml-3"
              color="success"
              :checked="mainObject.status"
              shape="pill"
              @update:checked="statusUpdate"
            />
      </CRow>
    </CCard>
    <CRow class="mt-3 mb-5">
      <CCol class="col-md-2 text-left">
        <div class="submit_offer">
        <CButton
          type="submit"
          v-on:click="onSubmit"
          color="success"
        >{{edit? "Save" : "Submit"}}</CButton>
      </div>
      <div style="display:none;" id="closeModal" @click="$emit('offermodal')">
        <CButton  variant="" color="danger">Close modal</CButton>
      </div>
    </CCol>
    </CRow>
    <!-- Show Custom Postback Model -->
    <CModal
      title="Custom Postback"
      :show.sync="show_custom_modal"
      :centered="true"
      scrollable
      color="dark"
    >
      <div>
        <CRow class="text-center">
          <CCol>
           Click here... <CHeaderNavLink to="/document/customizepostback">Documentation</CHeaderNavLink>
          </CCol>
        </CRow>
        <CRow class="mt-3">
          <CCol>
            <label>Add Macro</label>
          </CCol>
        </CRow>
        <CRow class="mt-1">
          <CCol>
            <multiselect
                class=""
                v-model="custom.macros"
                tag-placeholder="Add this as new tag"
                placeholder="Macros:"
                :options="cust_macros"
                :multiple="false"
                :taggable="true"
                @tag="addTag"
                @input="updateUrl(value)"
                :close-on-select="true"
              >
              <!-- <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template> -->
              </multiselect>
          </CCol>
        </CRow>
        <CRow class="mt-3">
          <CCol>
            <label>Postback URL</label>
          </CCol>
        </CRow>
        <CRow class="mt-1">
          <CCol>
            <CTextarea rows="3" id="custompostbackurl"
            cols="150" v-model="cust_pb_url" disabled/>
          </CCol>
        </CRow>
      </div>
      <template #footer>
        <CButton color="info" @click="copyToClipboard()">Copy URL</CButton>
        <CButton @click="show_custom_modal = false" color="danger">Close</CButton>
      </template>
    </CModal>
    <!-- Show Model -->
    <CModal
      title="Offer Details"
      :show.sync="show_details"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <offer-info></offer-info>
    <template #footer>
        <CButton @click="show_details = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>
    <vue-tour :name="'offer'"></vue-tour>
  </div>
</template>

<script>
import OfferService from './../../services/offerControlService'
import Config from './../../services/config'
import Multiselect from "vue-multiselect"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import SettingService from "./../../services/settingControlService";
import HelperFunction from "./../../helper/helperFunction"
import 'sweetalert2/src/sweetalert2.scss';
import VueTour from "../../components/vueTour.vue";
import OfferInfo from './OfferInfo.vue';
export default {
  name: 'NewOffer',
  props:{
    edit_offerdata:Object,
    offer_modal:Boolean,
  },
  components:{ Multiselect,VueTour,OfferInfo },
  data() {
        return {
        show_city: false,
        show_pwd_info:false,
        collapse:false,
        show_details:false,
        show_custom_modal:false,
        cust_pb_url:'',
        custom:{macros:''},
        apiKey:'',
        email:'',
        edit: false,
        show_box: false,
        // network_type: false,
        data: "",
        user: "",
        user_status: false,
        mainObject: {
        offername: "",
        offerurl: "",
        postbackurl: "",
        category: "0",
        revenuetrack: "0",
        capping: "0",
        eventurl: "",
        country: [],
        country_clude: false,
        city_clude: false,
        carriers: [],
        carriers_clude: false,
        networktype:{
          name: 'notGiven',
          enable: false
        },
        devicetype: [],
        devicetype_clude: false,
        os: [],
        os_clude: false
      },
      macros:['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12','rev','token','external_id'],
      cust_macros:['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12'],
      value: [],
      options: [
        { name: "Vue.js", code: "vu" },
        { name: "Javascript", code: "js" },
        { name: "Open Source", code: "os" }
      ],
      device: [
        { name: "Desktop", did: "1" },
        { name: "Phone", did: "2" },
        { name: "Smartphone", did: "3" },
        { name: "Tablet", did: "4" },
        { name: "Unknown", did: "5" }
      ],
      offername_txt: true,
      offerurl_txt: false,
      category_txt: false,
      revenuetrack_txt: false,
      capping_txt: false,
      targeting_txt: false,
      country: [],
      city:[],
      carrier: [],
      os: [],
      conve_url:"",
        }
        },
    watch:{
      offer_modal(value){
        this.edit = false;
        this.mainObject = {offername: "", offerurl: "", postbackurl: "", category: "0", revenuetrack: "0", capping: "0", eventurl: "", country: [], country_clude: false, city_clude: false, carriers: [], carriers_clude: false, networktype: {name: 'notGiven', enable: false}, devicetype: [], devicetype_clude: false, os: [], os_clude: false};
        if(value == true){
          if(window.localStorage.getItem('guide_tool') == 'true'){
            this.$tours['offer'].start()
          }
        if(this.edit_offerdata){
        this.mainObject={}
        this.mainObject = this.edit_offerdata;
        if(this.mainObject.city.length > 0){
          if(this.mainObject.city[0].name == '@@@'){
            this.mainObject.city = []
          }
        }
        if(this.mainObject.country != []){ this.show_city = true; }
        if(this.mainObject.capping == "99999" || this.mainObject.capping == 99999) this.mainObject.capping = 0
          if(this.mainObject.revenuetrack == '1'){this.show_box = true;}
          this.edit = true;
        this.checkBoxcheck()
        }
      }
      }
    },
      computed: {
        validation() {
          if (this.mainObject.offername == "") {
            return false;
          }else if(HelperFunction.validateName(this.mainObject.offername,100) == false){
              this.mainObject.offername = ''
              return false;
            }
            else{return true}
        },
        validateNumber(){
          if(HelperFunction.validateNumber(this.mainObject.revenuevalue,0,999999999999) == false){
            this.mainObject.revenuevalue = 0
          }
          if(HelperFunction.validateNumber(this.mainObject.capping,0,999999999999) == false){
          this.mainObject.capping = 0
          }

        }
      },
     async mounted(){
      if(window.localStorage.getItem('guide_tool') == 'true'){
        this.$tours['offer'].start()
        var classes = ['.create-offer','.traffic-source','.create-campaign','.sdk-menu','.report-menu','.campaign-list']
        classes.map((t)=> {
          var ele = document.querySelector(t);
          if(ele){ele.classList.remove('focus_guide');}
        })
        this.$tours['sidebar'].stop()
      }
       if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
        else{
        if (window.localStorage.getItem("user") == "null") {
          alert("Please Enter User Name and Password");
          this.$router.push("/pages/login");
        } 
        else if(window.localStorage.getItem("user") == 'csp'){
          console.log('new offer')
          return;
        }
        else {
          if (this.$root.$data.edit == true) {
            // console.log(JSON.stringify(this.$root.$data.offer))
            this.mainObject = this.$root.$data.offer;
            // document.getElementById("mainsteam0").checked = true;
            if(this.mainObject.capping == "99999" || this.mainObject.capping == 99999) this.mainObject.capping = 0
            if(this.mainObject.revenuetrack == '1'){this.show_box = true;}
            // this.mainObject.networktype.enable = true
            this.edit = true;
            this.$root.$data.edit = false;
          }
          this.checkBoxcheck()
          // this.mainObject.useremail = window.localStorage.getItem("email");
          this.user_status = window.localStorage.getItem("status");
          let res_country = await OfferService.country().then(res => {
            this.country = res.message;
          });
          let res_os = await OfferService.os().then(res => {
            this.os = res.message;
          });
          this.conve_url = Config.convurl;
        }
        this.checkButton()
        }
      },
    methods: {
    updateUrl(){
      var params = this.custom.macros
      // for multiple macro
      // for(var i = 0; i < params.length; i++){
      //   txt = txt.concat('&'+params[i] + '={' + params[i] + '}')
      // }
      var txt = '&'+params + '={' + params + '}'
      this.cust_pb_url=Config.customPostbackurl + '?useremail='+this.email+'&apiKey=' + this.apiKey.concat(txt);
    },
     async openCustModal(){
        this.show_custom_modal = true;
        this.custom.macros=[]
        let res = await SettingService.getdevapi();
        this.apiKey = res.message.masterKey;
        this.email = window.localStorage.getItem("email");
        this.cust_pb_url = Config.customPostbackurl + '?useremail='+this.email+'&apiKey=' + this.apiKey;
      },
      copyToClipboard() {
         var textField = document.getElementById("custompostbackurl");
        textField.select();
        textField.focus(); //SET FOCUS on the TEXTFIELD
        document.execCommand("copy");
        Swal.fire({
          title: "Success!",
          text: "Postback URL Copied!!",
          icon: "success",
          confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
        });
    },
      checkButton(){
      var params = ['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12','rev','token','external_id']
      for(let e in params) {
        // match value from array and remove it
        if( this.mainObject.offerurl.match(new RegExp('{'+params[e]+'}')) ){
          delete params[e]
        } 
        this.macros = params
        }
      },
      addMacros(data){
        // var str1 = "?";
        // for (var i = 0; i < this.mainObject.offerurl.length; i++) {
        //   if (this.mainObject.offerurl.charAt(i) == "?") {
        //     // var str1 = "&";
        //   }
        // }
      var str3 = "{" + data + "}";
      // let url_text = str1.concat(str3);
      let url_text = str3;
      this.mainObject.offerurl = this.mainObject.offerurl.concat(url_text)
      this.checkButton()
      },
    changeNetworkType(){
      if(this.mainObject.networktype.enable){
        document.getElementById("network_type1").checked = true;
        this.mainObject.networktype.name = 'mobile'
      }
    },
    openModel(){
      this.show_details = true
      console.log('open model')
    },
    validateUrl(str) {
      if(str){
          var res = str.match(/^((http(s)?):\/\/)?([a-z0-9]+:[a-z0-9]+@)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
          if(res == null){
              return false;
          }else{
          return true;
          }
      }
    },
    showTxt(txt) {
      this.offername_txt = false;
      this.offerurl_txt = false;
      this.category_txt = false;
      this.revenuetrack_txt = false;
      this.capping_txt = false;
      this.targeting_txt = false;
      switch (txt) {
        case "offername":
          this.offername_txt = true;
          break;
        case "offerurl":
          this.offerurl_txt = true;
          break;
        case "category":
          this.category_txt = true;
          break;
        case "revenuetrack":
          this.revenuetrack_txt = true;
          break;
        case "capping":
          this.capping_txt = true;
          break;
        case "targeting":
          this.targeting_txt = true;
          break;
      }
    },

    check(evnt,checkblank) {
      if (evnt == "1") {
        this.show_box = true;
      } else {
        this.show_box = false;
      }
      if(checkblank != '1'){
        this.mainObject.revenuevalue = 0
      }
    },
    async onSubmit() {
      try {
        if((this.mainObject.offername == '') || (this.mainObject.offerurl == '')){
          Swal.fire({title: 'Error!',text: "Please fill all required fields",icon: 'warning',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
          //  this.errorNotify("Error", "Please field all required fields", "error");
        }else{
        if(this.validateUrl(this.mainObject.offerurl) == true){


        // console.log(JSON.stringify(this.mainObject))

          if(this.mainObject.networktype.enable == false || this.mainObject.networktype.enable == "false") {
            delete this.mainObject.networktype.name
          }
          if (this.edit) {
          let updated_data = {
            offerid: this.mainObject._id,
            changePayload: this.mainObject
          };
            if(this.mainObject.capping == "0" || this.mainObject.capping == null ||  this.mainObject.capping == "") {
              this.mainObject.capping = "99999"
            }
          let response = await OfferService.edit(updated_data)

          if (response.result == true) {
            Swal.fire({title: 'Success!',text: "Offer edited",icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
            // this.$router.push("/offer/offerlist");
            // window.location.reload();
          } else {
            Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
            // this.errorNotify("Error","Invalid username and password","danger");
          }
          this.edit=false
        } else {
            if(this.mainObject.capping == "0" || this.mainObject.capping == null ||  this.mainObject.capping == "") {
              this.mainObject.capping = "99999"
            }
          let response = await OfferService.add(this.mainObject)
          // .then(res => {
          //   this.errorNotify("Success", res.message, "success");
          // });
          if (response.result == true) {
            Swal.fire({title: 'Success!',text: "Offer Created",icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
            if(window.localStorage.getItem('guide_tool') == 'true'){
            var el = document.querySelector('#next-btn');
            if(el){el.click() }
            }
            // else{this.$router.push("/offer/offerlist");}
          }
          else{
            Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
            // this.errorNotify("Error", "Offername already exist!", "danger");
          }
        }
        this.$emit('offermodal')
      }else{
        Swal.fire({title: 'Error!',text: "Wrong URL!",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
          // this.errorNotify("Error", "Wrong URL URL", "error");
        }
        }
        }
      catch (e) {
        this.$emit('ready', false)
        // if (formData.isactive == false) {
        //   formData.isactive = true;
        // } else {
        //   formData.isactive = false;
        // }
      }
    },
    onReset() {
      swal("Are you sure you want to Reset?", {
        buttons: ["No!", "Confirm!"]
      }).then(value => {
        if (value == true) {
          this.mainObject = [];
          this.mainObject.offer = null;
          this.mainObject.traffic_source = null;
        }
      });
    },
    async getcarrier() {
      this.show_city = false;
      let cndata = this.mainObject.country.map(r => {
        return r._id;
      });
      let alpha_2 = this.mainObject.country.map(r => {
        return r.alpha_2;
      });
      let res_city = await OfferService.getcity({ "country" : alpha_2 }).then(res => {
        if (res) {
          this.city = res.message;
        }
      });
      if(this.mainObject.country.length == 0){
        this.mainObject.city =[]
      }
      if(this.mainObject.country.length > 0){
        this.show_city = true;
      }
      let res_carriers = await OfferService.getcarrier({ "country" : cndata }).then(res => {
        if (res) {
          this.carrier = res.message;
        }
      });
      this.checkBoxcheck()
    },
    logout() {
      swal("Are you sure you want to Logout?", {
        buttons: ["No!", "Yes!"]
      }).then(value => {
        if (value == true) {
          window.localStorage.setItem("user", null);
          window.localStorage.setItem("status", null);
          this.$router.push("Login");
        }
      });
    },
    toFormData(obj) {
      var formData = new FormData();
      for (var key in obj) {
        formData.append(key, obj[key]);
      }
      // if(window.$cookies.get('type')){
      //   formData.append('user_type',window.$cookies.get('type'))
      // }
      return formData;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        _id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    checkBoxcheck(){
        switch(this.mainObject.category){
          case 0 :
                var el = document.querySelectorAll("#mainsteam0")
                el.forEach((e)=>{e.checked = true})
          break;
          case 1 : 
                var el = document.querySelectorAll("#mainsteam1")
                el.forEach((e)=>{e.checked = true})
          break;
        }
        switch(this.mainObject.revenuetrack){
          case 0 : 
                var el = document.querySelectorAll("#revenuetrack0")
                el.forEach((e)=>{e.checked = true})
          break;
          case 1 : 
                var el = document.querySelectorAll("#revenuetrack1")
                el.forEach((e)=>{e.checked = true})
          break;
          case 2 : 
                var el = document.querySelectorAll("#revenuetrack2")
                el.forEach((e)=>{e.checked = true})
          break;
        }
        switch(this.mainObject.country_clude){
          case true :
                var el = document.querySelectorAll("#country1")
                el.forEach((e)=>{e.checked = true})
          break;
          case false :
                var el = document.querySelectorAll("#country2")
                el.forEach((e)=>{e.checked = true})
          break;
        }
        switch(this.mainObject.city_clude){
          case true :
                var el = document.querySelectorAll("#city1")
                el.forEach((e)=>{e.checked = true})
          break;
          case false :
                var el = document.querySelectorAll("#city2")
                el.forEach((e)=>{e.checked = true})
          break;
        }
        switch(this.mainObject.carriers_clude){
          case true :
                var el = document.querySelectorAll("#carrier1")
                el.forEach((e)=>{e.checked = true})
          break;
          case false : 
                var el = document.querySelectorAll("#carrier2")
                el.forEach((e)=>{e.checked = true})
          break;
        }
        switch(this.mainObject.devicetype_clude){
          case true :
                var el = document.querySelectorAll("#devicetype1")
                el.forEach((e)=>{e.checked = true})
          break;
          case false :
                var el = document.querySelectorAll("#devicetype2")
                el.forEach((e)=>{e.checked = true})
          break;
        }
        switch(this.mainObject.os_clude){
          case true :
                var el = document.querySelectorAll("#os1")
                el.forEach((e)=>{e.checked = true})
          break;
          case false : 
                var el = document.querySelectorAll("#os2")
                el.forEach((e)=>{e.checked = true})
          break;
        }
        switch(this.mainObject.networktype.name){
          case 'mobile' :
                var el = document.querySelectorAll("#network_type1")
                el.forEach((e)=>{e.checked = true})
                break;
          case 'wifi' : 
                var el = document.querySelectorAll("#network_type2")
                el.forEach((e)=>{e.checked = true})
                break;
               
        }
    },
    statusUpdate() {
      var status = true
        if (this.mainObject.status == true) {
          status = false;
        } else {
          status= true;
        }
        this.mainObject.status = status
    }
         
  }
}
</script>
<style scoped>
.url_text{
  font-family: 'Oswald', sans-serif;
  color: #000;
  font-weight: 600;
}
.link{
  color: blue;
  cursor: pointer;
}
</style>
